<script lang="ts">var _a;
import { stringSepToNorm, flattenData } from "../random";
import { rx } from "../rxfsm";
import auth from "../auth";
const userRoles = auth.claims.pipe(rx.filter(val => val != null), rx.pluck("https://hasura.io/jwt/claims"), rx.pluck("x-hasura-allowed-roles"));
import { QueryStore } from "../GraphQL/query";
import { getQueryFirstSelectionName, getQueryFirstSelection, getSourceSelections, getQueryVariableType } from "../GraphQL/helpers";
import GeneralHeading from "../General/GeneralHeading.svelte";
import FormField from "./Form/FormField.svelte";
import TableSortButton from "./Table/TableSortButton.svelte";
import tableActions from "./Table/tableActions";
import Loader from "../General/Loader.svelte";
import TreeNodes from "./Tree/TreeNodes.svelte";
export let options;
const tableName = getQueryFirstSelectionName(options === null || options === void 0 ? void 0 : options.query);
let sortStates = {};
let whereValues = {};
let tableKeyParentsByKey;
let tableData = [];
let tableKeys = [];
let selectedRow;
const queryStore = new QueryStore({
    query: options === null || options === void 0 ? void 0 : options.query,
    variables: { order_by: {} },
    paginate: true,
    insertQueryVariables: { order_by: `[${tableName}_order_by!]` },
});
const queryVariablesStore = queryStore.variablesStore;
const queryDataStore = queryStore.getDataStore();
const getOrderBy = () => Object.fromEntries(Object.entries(sortStates)
    .filter(([k, v]) => v != null)
    .map(([k, v]) => {
    var _a, _b;
    return tableKeyParentsByKey[k].length == 0
        ? [(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k, v]
        : [
            //Sorry to anyone who has to debug this function
            tableKeyParentsByKey[k][0],
            [...tableKeyParentsByKey[k].slice(1), (_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k, v].reduceRight((a, cv) => {
                let n = {};
                n[cv] = a;
                return n;
            }),
        ];
}));
const getWheres = () => Object.fromEntries(Object.entries(whereValues)
    .filter(([k, v]) => v != undefined)
    .map(([k, v]) => {
    var _a, _b;
    return tableKeyParentsByKey[k].length == 0
        ? [(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k, findWhereValue(k, v)]
        : [
            //Sorry to anyone who has to debug this function
            tableKeyParentsByKey[k][0],
            [...tableKeyParentsByKey[k].slice(1), (_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k, findWhereValue(k, v)].reduceRight((a, cv) => {
                let n = {};
                n[cv] = a;
                return n;
            }),
        ];
})
    .map(([k, v]) => [`where_${k}`, v]));
const getAvailableActions = () => { var _a, _b; return (_b = (_a = options === null || options === void 0 ? void 0 : options.actions) === null || _a === void 0 ? void 0 : _a.map(getTableAction)) !== null && _b !== void 0 ? _b : []; };
const getTableAction = (action) => {
    const key = typeof action == "string" ? action : action["key"];
    return { ...tableActions[key](action), key: key };
};
$: if ((_a = $queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.data) === null || _a === void 0 ? void 0 : _a.data) {
    tableData = $queryDataStore["data"]["data"].map((en) => {
        const { data, keyParentsByKey } = flattenData(en);
        tableKeyParentsByKey = keyParentsByKey;
        return data;
    });
    setSelectedRowNull();
}
const setSelectedRowNull = () => (selectedRow = null);
const removeKeys = (obj, pattern) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => !pattern.test(k)));
$: if (tableData[0])
    tableKeys = Object.keys(tableData[0]).filter((key) => { var _a; return !((_a = options.queryHidden) !== null && _a !== void 0 ? _a : []).includes(key); });
$: if (Object.keys(sortStates).length > 0)
    ($queryVariablesStore = { ...$queryVariablesStore, order_by: getOrderBy() }), sortStates;
$: if (Object.keys(whereValues).length > 0)
    ($queryVariablesStore = { ...removeKeys($queryVariablesStore, /where_(.*?)/), ...getWheres() }), whereValues;
const findAliasName = (alias, source = getQueryFirstSelection(options.query)) => {
    var _a;
    const selections = getSourceSelections(source);
    let found;
    for (const sel of selections) {
        if (((_a = sel.alias) === null || _a === void 0 ? void 0 : _a.value) == alias)
            found = sel["name"]["value"];
        if (sel.selectionSet) {
            const subSearch = findAliasName(alias, sel);
            if (subSearch)
                found = subSearch;
        }
        if (found)
            break;
    }
    return found;
};
const findWhereValue = (k, v) => {
    var _a;
    const queryVarType = getQueryVariableType(options.query, `where_${(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k}`);
    if (queryVarType === null || queryVarType === void 0 ? void 0 : queryVarType.includes("_enum_comparison_exp")) {
        return { _eq: v };
    }
    switch (queryVarType) {
        case "String_comparison_exp":
            return { _ilike: `%${v}%` };
        case "uuid_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Int_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "timestamptz_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "numeric_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "bigint_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "date_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Boolean_comparison_exp":
            return { _eq: v };
        case "timestamp_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "jsonb_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
    }
};
const findFilterOptions = (k) => {
    var _a, _b;
    const queryVarType = getQueryVariableType(options.query, `where_${(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k}`);
    if (queryVarType === null || queryVarType === void 0 ? void 0 : queryVarType.includes("_enum_comparison_exp")) {
        return { type: "select", optionsQuery: options["varOptions"][`where_${(_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k}`]["optionsQuery"] };
    }
    switch (queryVarType) {
        case "String_comparison_exp":
            return { type: "text" };
        case "uuid_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Int_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "timestamptz_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "numeric_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "bigint_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "date_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Boolean_comparison_exp":
            return { type: "select", options: [false, true] };
        case "timestamp_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "jsonb_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        default:
            return { type: "text", placeholder: "Missing Filter Type" };
    }
};
</script>

<style>.clickableAction {
  opacity: 0.5;
  cursor: not-allowed;
}</style>

<div class="overflow-x-auto">
  <div class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border border-gray-400 dark:border-gray-900">
    {#key options?.actions}
      <GeneralHeading title={stringSepToNorm(tableName, '_')}>
        {#each getAvailableActions() as action}
          {#if action['roles'] ? auth.helper.checkRoles($userRoles, action['roles']) : true}
          <button
            type="button"
            id={tableName + '-button' + action['key']}
            class="actionButton"
            class:clickableAction={action.needSelectedRow ? !selectedRow : false}
            on:click={() => action.action(tableName, selectedRow, () => {
                selectedRow = null;
                queryStore.doQuery();
              })}
            disabled={action.needSelectedRow ? !selectedRow : false}>
            {action?.label}
          </button>
          {/if}
        {/each}
      </GeneralHeading>
    {/key}

    {#if $queryDataStore?.loading}
      <Loader />
    {:else if !tableKeys}
      <GeneralHeading title="No data found" />
    {:else}
      <div class="grid grid-cols-2 px-6 py-4">
        <div class="col-span-1">
          {#each tableKeys as key}
            <label for={tableName + '-' + key}>{stringSepToNorm(key, '_')}</label>
            <div class="py-2 pr-10 pb-3">
              <FormField
                bind:value={whereValues[key]}
                fieldData={{ field: key, label: key, placeholder: 'Search', width: 'full', ...findFilterOptions(key) }}
                hideLabel />
            </div>
          {/each}
        </div>
        <div class="col-span-1">
          <TreeNodes
            tableData={{ name: stringSepToNorm(tableName, '_'), nodes: tableData }}
            leaficon={options.leaficon}
            nodeicon={options.nodeicon}
            bind:selectedRow
            nodeQuery={options.nodeQuery} />
        </div>
      </div>
    {/if}
  </div>
</div>
