<script lang="ts">import { areDatesSameDay, createOffset, adjustForTimezone, MONTH_NAMES, DAYS, pad } from "./helpers";
import { clickOutside } from "@src/random";
import { fade } from "svelte/transition";
export let passClass = "";
export let inputPassClass = "";
export let id, value, required;
export let showDateTimepicker = false;
export let closeOnSelect = true;
export let roundRightonFocus = true;
let dateTimeValue;
let dateStringWithOffset;
let month;
let year;
let hours;
let minutes;
let no_of_days = [];
let blankdays = [];
$: if (value)
    dateTimeValue = new Date(value);
$: if (dateTimeValue)
    setOthersFromDateTimeValue();
const toggleDatePicker = () => {
    if (!value) {
        dateTimeValue = new Date();
        dateTimeValue.setMilliseconds(0);
        setValueFromDateTimeValue();
    }
    if (year && month)
        setNoOfDays();
    showDateTimepicker = !showDateTimepicker;
};
const setOthersFromDateTimeValue = () => {
    month = parseInt(pad(dateTimeValue.getMonth(), 2));
    year = dateTimeValue.getFullYear();
    hours = dateTimeValue.getHours();
    minutes = dateTimeValue.getMinutes();
    dateStringWithOffset = adjustForTimezone(dateTimeValue).toISOString().replace(":00.000Z", "") + createOffset(dateTimeValue);
    if (no_of_days.length == 0)
        setNoOfDays();
};
const setValueFromDateTimeValue = () => {
    value = dateTimeValue.toISOString().replace(".000Z", "") + "+00:00";
};
const setDateValue = (_year, _month, date) => {
    const newDateTimeValue = new Date(dateTimeValue.setDate(date));
    newDateTimeValue.setFullYear(_year);
    newDateTimeValue.setMonth(_month);
    dateTimeValue = newDateTimeValue;
    setValueFromDateTimeValue();
    if (closeOnSelect)
        showDateTimepicker = false;
};
const setHourValue = (hours) => {
    dateTimeValue = new Date(dateTimeValue.setHours(hours));
    setValueFromDateTimeValue();
    if (closeOnSelect)
        showDateTimepicker = false;
};
const setMinuteValue = (minutes) => {
    dateTimeValue = new Date(dateTimeValue.setMinutes(minutes));
    setValueFromDateTimeValue();
    if (closeOnSelect)
        showDateTimepicker = false;
};
const setNoOfDays = () => {
    const dayOfWeek = new Date(year, month).getDay();
    let blankdaysArray = [];
    for (var i = 1; i <= dayOfWeek; i++) {
        blankdaysArray.push(i);
    }
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let daysArray = [];
    for (var i = 1; i <= daysInMonth; i++) {
        daysArray.push(i);
    }
    blankdays = blankdaysArray;
    no_of_days = daysArray;
};
</script>

<style>:global(.datepicker) {
  border: 1px solid #aaa;
}</style>

<div class="relative {passClass}">
  <div class="relative {roundRightonFocus || !showDateTimepicker ? 'rounded-md' : 'rounded-l-md'} shadow-sm">
    <input
      value={dateStringWithOffset ?? ""}
      type="text"
      on:keydown|preventDefault
      on:paste|preventDefault
      placeholder="DateTime"
      {id}
      {required}
      on:click={toggleDatePicker}
      class="form-input dark:bg-gray-800 {inputPassClass} {roundRightonFocus || !showDateTimepicker ? '' : 'rounded-r-none'} block w-full text-left
        sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
  </div>
  {#if showDateTimepicker}
    <div
      use:clickOutside={{ callback: toggleDatePicker, exclusions: [id] }}
      class="datepicker bg-white dark:bg-gray-800  mt-12 rounded-lg shadow py-2 px-3 absolute top-0 right-0 z-50 flex flex-row space-x-2"
      style="width: 23rem"
      transition:fade={{ duration: 150 }}>
      <div class="flex flex-col">
        <div class="flex flex-row flex-no-wrap justify-between items-center mb-2">
          <div class="flex flex-row flex-no-wrap">
            <span class="text-lg font-bold text-secondary-800 dark:text-gray-200">{MONTH_NAMES[month]}</span>
            <span class="ml-1 text-lg text-secondary-600 font-normal">
              <input
                class="w-full dark:bg-gray-800"
                type="text"
                bind:value={year}
                on:change={(val) => {
                  setNoOfDays();
                }} />
            </span>
          </div>
          <div class="flex flex-row flex-no-wrap">
            <button
              type="button"
              class="{month == 0 ? 'cursor-not-allowed opacity-25' : ''} transition ease-in-out duration-100 inline-flex
                cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              disabled={month == 0}
              on:click={() => {
                month--;
                setNoOfDays();
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button
              type="button"
              class="{month == 11 ? 'cursor-not-allowed opacity-25' : ''} transition ease-in-out duration-100
                inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              disabled={month == 11}
              on:click={() => {
                month++;
                setNoOfDays();
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>

        <div class="flex flex-wrap mb-3 -mx-1">
          {#each DAYS as day, index}
            <div style="width: 14.26%" class="px-0.5">
              <div class="text-secondary-800 dark:text-gray-200 font-medium text-center text-xs">{day}</div>
            </div>
          {/each}
        </div>

        <div class="flex flex-wrap -mx-1">
          {#each blankdays as blankday}
            <div style="width: 14.28%" class="text-center border p-1 border-transparent text-xs" />
          {/each}
          {#each no_of_days as date, dateIndex}
            <div style="width: 14.28%" class="px-0.5 mb-0.5">
              <div
                on:click={() => setDateValue(year, month, date)}
                class="{areDatesSameDay(new Date(year, month, date), adjustForTimezone(dateTimeValue)) ? 'bg-secondary-500 text-white' : 'text-secondary-700 hover:bg-secondary-200 dark:text-secondary-200 dark:hover:bg-secondary-500'}
                      cursor-pointer text-center text-xs rounded-full leading-loose transition ease-in-out
                      duration-100">
                {date}
              </div>
            </div>
          {/each}
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <div class="flex flex-row space-x-4">
          <div class="flex flex-col space-y-1">
            <button
              type="button"
              class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              on:click={() => {
                setHourValue(hours + 1);
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <input
              value={pad(hours, 2)}
              type="button"
              readonly
              on:change={() => {
                setHourValue(hours);
              }}
              class="form-input dark:bg-gray-800 block w-full text-left sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
            <button
              type="button"
              class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              on:click={() => {
                setHourValue(hours - 1);
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>

          <div class="flex flex-col space-y-1">
            <button
              type="button"
              class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              on:click={() => {
                setMinuteValue(minutes + 1);
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <input
              value={pad(minutes, 2)}
              type="button"
              readonly
              on:change={() => {
                setMinuteValue(minutes);
              }}
              class="form-input dark:bg-gray-800 block w-full text-left sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
            <button
              type="button"
              class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              on:click={() => {
                setMinuteValue(minutes - 1);
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full focus:outline-none"
          on:click={() => {
            toggleDatePicker();
            value = undefined;
            dateStringWithOffset = undefined;
          }}>
          <span class="text-secondary-500 inline-flex mx-auto my-1 text-center"> Clear </span>
        </button>
      </div>
    </div>
  {/if}
</div>
