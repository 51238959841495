<script lang="ts">export let id, value = false, required, disabled;
export let isUnsavedUpdates = false;
export let roundRightonFocus = true;
export let isFocused = false;
$: if (value == null || value == undefined)
    value = false;
</script>

<style>.toggleBtnWrapper {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 0.285rem;
  padding-bottom: 0.285rem;
}

.toggleBtn {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  height: 1.5rem;
  width: 2.75rem;
  border-width: 2px;
  border-color: transparent;
  border-radius: 9999px;
  cursor: pointer;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.toggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: opacity;
}

.show {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;
}

.hide {
  opacity: 0;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 100ms;
}</style>

<div
  class="toggleBtnWrapper form-input {!isUnsavedUpdates ? 'bg-transparent border-0 px-0' : ''} {!!disabled ? 'bg-gray-200' : ''} {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''}">
  <button
    type="button"
    on:click={() => (value = !value)}
    aria-pressed="{!!value}"
    class="{value ? 'bg-secondary-600' : 'bg-gray-200'} toggleBtn ml-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500">
    <span class="sr-only">Use setting</span>
    <span
      class="{value ? 'translate-x-5' : 'translate-x-0'} relative inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-800  shadow transform ring-0 transition ease-in-out duration-200">
      <span class:show={!value} class:hide={!!value} class="toggle" aria-hidden="true">
        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span class:show={!!value} class:hide={!value} class="toggle" aria-hidden="true">
        <svg class="h-3 w-3 text-secondary-600" fill="currentColor" viewBox="0 0 12 12">
          <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
        </svg>
      </span>
    </span>
  </button>
</div>
