<script lang="ts">var _a, _b;
import FormFieldGroup from "../FormFieldGroup.svelte";
import { mutateClient } from "@src/GraphQL/mutate";
import { queryClient } from "@src/GraphQL/query";
import { clickOutside } from "@src/random";
import { modalOpts } from "@src/stores";
export let id, placeholder, value, required, disabled;
export let isUnsavedUpdates = false;
export let roundRightonFocus = true;
export let fieldData;
$: isMulti = (_a = fieldData === null || fieldData === void 0 ? void 0 : fieldData.multiple) !== null && _a !== void 0 ? _a : false;
var SELECTSTATE;
(function (SELECTSTATE) {
    SELECTSTATE[SELECTSTATE["INITIAL"] = 0] = "INITIAL";
    SELECTSTATE[SELECTSTATE["OPEN"] = 1] = "OPEN";
    SELECTSTATE[SELECTSTATE["FILTERED"] = 2] = "FILTERED";
    SELECTSTATE[SELECTSTATE["CLOSED"] = 3] = "CLOSED";
})(SELECTSTATE || (SELECTSTATE = {}));
let csState = SELECTSTATE.INITIAL;
let csInput;
let csList;
const csOptions = () => csList.querySelectorAll("li");
const aOptions = () => Array.from(csOptions());
let showList;
function findFocus() {
    const focusPoint = document.activeElement;
    return focusPoint;
}
function moveFocus(fromHere, toThere) {
    // grab the currently showing options, which might have been filtered
    const aCurrentOptions = aOptions().filter(function (option) {
        if (option.style.display === "") {
            return true;
        }
    });
    // don't move if all options have been filtered out
    if (aCurrentOptions.length === 0) {
        return;
    }
    if (toThere === "input") {
        csInput.focus();
    }
    // possible start points
    switch (fromHere) {
        case csInput:
            if (toThere === "forward") {
                aCurrentOptions[0].focus();
            }
            else if (toThere === "back") {
                aCurrentOptions[aCurrentOptions.length - 1].focus();
            }
            break;
        case csOptions()[0]:
            if (toThere === "forward") {
                aCurrentOptions[1].focus();
            }
            else if (toThere === "back") {
                csInput.focus();
            }
            break;
        case csOptions()[csOptions().length - 1]:
            if (toThere === "forward") {
                aCurrentOptions[0].focus();
            }
            else if (toThere === "back") {
                aCurrentOptions[aCurrentOptions.length - 2].focus();
            }
            break;
        default:
            // middle list or filtered items
            const currentItem = findFocus();
            const whichOne = aCurrentOptions.indexOf(currentItem);
            if (toThere === "forward") {
                const nextOne = aCurrentOptions[whichOne + 1];
                nextOne.focus();
            }
            else if (toThere === "back" && whichOne > 0) {
                const previousOne = aCurrentOptions[whichOne - 1];
                previousOne.focus();
            }
            else {
                // if whichOne = 0
                csInput.focus();
            }
            break;
    }
}
function doFilter() {
    if (!(fieldData === null || fieldData === void 0 ? void 0 : fieldData.optionsSearchQuery)) {
        const splitInputValue = csInput.value.split(",");
        const terms = splitInputValue[splitInputValue.length - 1];
        const aFilteredOptions = aOptions().filter(function (option) {
            if (option.innerText.toUpperCase().includes(terms.toUpperCase())) {
                return true;
            }
        });
        csOptions().forEach((option) => (option.style.display = "none"));
        aFilteredOptions.forEach((option) => {
            option.style.display = "";
        });
        csState = SELECTSTATE.FILTERED;
    }
}
function makeChoice(optionLabel, doFocus = true) {
    var _a;
    if (optionLabel == undefined)
        return;
    const foundOption = options.find((en) => { var _a; return (_a = (en === null || en === void 0 ? void 0 : en.label) === optionLabel) !== null && _a !== void 0 ? _a : en === optionLabel; });
    const newValue = (_a = foundOption === null || foundOption === void 0 ? void 0 : foundOption.value) !== null && _a !== void 0 ? _a : foundOption;
    if (isMulti) {
        selectForMulti(newValue, optionLabel);
    }
    else {
        value = newValue;
        csInput.value = optionLabel;
    }
    if (doFocus)
        moveFocus(document.activeElement, "input");
}
$: selectOptionLabels(), selectedOptionLabels;
function selectOptionLabels() {
    if (csList) {
        aOptions().forEach((el) => {
            if (selectedOptionLabels.includes(el.innerText)) {
                const isDark = document.documentElement.classList.contains("dark");
                el.style.backgroundColor = isDark ? "var(--primary-800)" : "var(--primary-200)";
            }
            else {
                el.style.backgroundColor = "";
            }
        });
    }
}
var delayTimer;
async function doKeyAction({ key }) {
    var _a;
    if (!disabled) {
        const currentFocus = findFocus();
        switch (key) {
            case "Enter":
                if (csState === SELECTSTATE.INITIAL) {
                    // if state = initial, toggleOpen and set state to opened
                    showList = true;
                    csState = SELECTSTATE.OPEN;
                }
                else if (csState === SELECTSTATE.OPEN &&
                    currentFocus.tagName === "LI") {
                    // if state = opened and focus on list, makeChoice and set state to closed
                    makeChoice(currentFocus.textContent);
                    showList = false;
                    csState = SELECTSTATE.CLOSED;
                }
                else if (csState === SELECTSTATE.OPEN && currentFocus === csInput) {
                    // if state = opened and focus on input, close it
                    showList = false;
                    csState = SELECTSTATE.CLOSED;
                }
                else if (csState === SELECTSTATE.FILTERED &&
                    currentFocus.tagName === "LI") {
                    // if state = filtered and focus on list, makeChoice and set state to closed
                    makeChoice(currentFocus.textContent);
                    showList = false;
                    csState = SELECTSTATE.CLOSED;
                }
                else if (csState === SELECTSTATE.FILTERED &&
                    currentFocus === csInput) {
                    // if state = filtered and focus on input, set state to opened
                    showList = true;
                    csState = SELECTSTATE.OPEN;
                }
                else {
                    // i.e. csState is closed, or csState is opened/filtered but other focus point?
                    // if state = closed, set state to filtered? i.e. open but keep existing input?
                    showList = true;
                    csState = SELECTSTATE.FILTERED;
                }
                break;
            case "Escape":
                // if state = initial, do nothing
                // if state = opened or filtered, set state to initial
                // if state = closed, do nothing
                if (csState === SELECTSTATE.OPEN || csState === SELECTSTATE.FILTERED) {
                    showList = false;
                    csState = SELECTSTATE.INITIAL;
                }
                break;
            case "ArrowDown":
                if (csState === SELECTSTATE.INITIAL || csState === SELECTSTATE.CLOSED) {
                    // if state = initial or closed, set state to opened and moveFocus to first
                    showList = true;
                    moveFocus(csInput, "forward");
                    csState = SELECTSTATE.OPEN;
                }
                else {
                    // if state = opened and focus on input, moveFocus to first
                    // if state = opened and focus on list, moveFocus to next/first
                    // if state = filtered and focus on input, moveFocus to first
                    // if state = filtered and focus on list, moveFocus to next/first
                    showList = true;
                    moveFocus(currentFocus, "forward");
                }
                break;
            case "ArrowUp":
                if (csState === SELECTSTATE.INITIAL || csState === SELECTSTATE.CLOSED) {
                    // if state = initial, set state to opened and moveFocus to last
                    // if state = closed, set state to opened and moveFocus to last
                    showList = true;
                    moveFocus(csInput, "back");
                    csState = SELECTSTATE.OPEN;
                }
                else {
                    // if state = opened and focus on input, moveFocus to last
                    // if state = opened and focus on list, moveFocus to prev/last
                    // if state = filtered and focus on input, moveFocus to last
                    // if state = filtered and focus on list, moveFocus to prev/last
                    moveFocus(currentFocus, "back");
                }
                break;
            default:
                if (fieldData.optionsSearchQuery) {
                    clearTimeout(delayTimer);
                    delayTimer = setTimeout(async function () {
                        await queryClient({
                            query: fieldData.optionsSearchQuery,
                            variables: { searchInput: `%${csInput.value}%` },
                        }).then(({ data }) => {
                            setOptions(data === null || data === void 0 ? void 0 : data.data);
                        });
                    }, 500);
                }
                if (!isMulti) {
                    value = null;
                }
                else {
                    value = [...((_a = value === null || value === void 0 ? void 0 : value.filter((en) => en != null)) !== null && _a !== void 0 ? _a : []), null];
                }
                if (csState === SELECTSTATE.INITIAL) {
                    // if state = initial, toggle open, doFilter and set state to filtered
                    showList = true;
                    doFilter();
                    csState = SELECTSTATE.FILTERED;
                }
                else if (csState === SELECTSTATE.OPEN) {
                    // if state = opened, doFilter and set state to filtered
                    doFilter();
                    csState = SELECTSTATE.FILTERED;
                }
                else if (csState === SELECTSTATE.CLOSED) {
                    // if state = closed, doFilter and set state to filtered
                    doFilter();
                    csState = SELECTSTATE.FILTERED;
                }
                else {
                    // already filtered
                    doFilter();
                }
                break;
        }
    }
}
function onClickSelect(e) {
    if (!disabled) {
        const currentFocus = findFocus();
        switch (csState) {
            case SELECTSTATE.INITIAL: // if state = initial, toggleOpen and set state to opened
                showList = true;
                csState = SELECTSTATE.OPEN;
                doFilter();
                break;
            case SELECTSTATE.OPEN:
                // if state = opened and focus on input, toggleShut and set state to initial
                if (currentFocus === csInput) {
                    showList = false;
                    csState = SELECTSTATE.INITIAL;
                }
                else if (currentFocus.tagName === "LI") {
                    // if state = opened and focus on list, makeChoice, toggleShut and set state to closed
                    makeChoice(currentFocus.textContent);
                    if (!isMulti) {
                        showList = false;
                        csState = SELECTSTATE.CLOSED;
                    }
                }
                else if (currentFocus.tagName === "BUTTON") {
                    if (currentFocus.id === "buttonclear") {
                        if (!isMulti) {
                            value = null;
                        }
                        else {
                            value = [];
                        }
                        selectedOptionLabels = [];
                        csInput.value = "";
                        setupOptions();
                        doFilter();
                    }
                    else if (currentFocus.id === "buttonaddnew") {
                        addNew();
                    }
                    if (!isMulti) {
                        showList = false;
                        csState = SELECTSTATE.CLOSED;
                    }
                }
                break;
            case SELECTSTATE.FILTERED:
                // if state = filtered and focus on list, makeChoice and set state to closed
                if (currentFocus.tagName === "LI") {
                    makeChoice(currentFocus.textContent);
                    if (!isMulti) {
                        showList = false;
                        csState = SELECTSTATE.CLOSED;
                    }
                }
                else if (currentFocus.tagName === "BUTTON") {
                    if (currentFocus.id === "buttonclear") {
                        if (!isMulti) {
                            value = null;
                        }
                        else {
                            value = [];
                        }
                        selectedOptionLabels = [];
                        csInput.value = "";
                        setupOptions();
                        doFilter();
                    }
                    else if (currentFocus.id === "buttonaddnew") {
                        addNew();
                    }
                    if (!isMulti) {
                        showList = false;
                        csState = SELECTSTATE.CLOSED;
                    }
                }
                break;
            case SELECTSTATE.CLOSED: // if state = closed, toggleOpen and set state to filtered? or opened?
                showList = true;
                csState = SELECTSTATE.FILTERED;
                break;
        }
    }
}
function isLabelSelected(option) {
    var _a;
    const optionValue = (_a = option === null || option === void 0 ? void 0 : option.value) !== null && _a !== void 0 ? _a : option;
    if (isMulti) {
        return value === null || value === void 0 ? void 0 : value.includes(optionValue);
    }
    return value === optionValue;
}
function setOptions(data) {
    var _a;
    options = (_a = data === null || data === void 0 ? void 0 : data.map((en) => {
        var _a, _b, _c;
        const label = ((_b = (_a = en["label"]) !== null && _a !== void 0 ? _a : en["key"]) !== null && _b !== void 0 ? _b : en).toString();
        const field = label.toLowerCase().split(" ").join("-");
        return { value: (_c = en["key"]) !== null && _c !== void 0 ? _c : en, label, field };
    })) !== null && _a !== void 0 ? _a : [];
}
async function setupOptions() {
    var _a;
    if (fieldData.optionsSearchQuery) {
        await queryClient({ query: fieldData.optionsSearchQuery }).then(({ data }) => {
            setOptions(data === null || data === void 0 ? void 0 : data.data);
        });
    }
    else if (fieldData.optionsQuery) {
        await queryClient({ query: fieldData.optionsQuery }).then(({ data }) => {
            setOptions(data === null || data === void 0 ? void 0 : data.data);
        });
    }
    else {
        setOptions((_a = fieldData.options) !== null && _a !== void 0 ? _a : ["No available options"]);
    }
}
function addNew() {
    csInput.value = setInitial;
    modalOpts.set({
        title: `Add Option`,
        description: `Fill in the Form to add an option to ${fieldData.label}`,
        color: "primary",
        exclusions: ["buttonaddnew"],
        body: FormFieldGroup,
        bodyOptions: { fields: fieldData.listAction.fields },
        action: {
            name: "Save",
            callback: async (bodyValue) => {
                const variables = {
                    ...Object.fromEntries(Object.entries(bodyValue).map(([field, value]) => [
                        `param_${field}`,
                        value,
                    ])),
                };
                const { data } = await mutateClient({
                    mutation: fieldData.listAction.mutation,
                    variables,
                });
                await setupOptions();
                const { label: newLabel, key: newKey } = data.data;
                if (isMulti) {
                    selectForMulti(newKey, newLabel);
                }
                else {
                    csInput.value = newLabel;
                    value = newKey;
                }
            },
        },
    });
}
const selectForMulti = (newKey, newLabel) => {
    var _a;
    if (!selectedOptionLabels.includes(newLabel)) {
        value = [
            ...((_a = value === null || value === void 0 ? void 0 : value.filter((en) => en != null && en != newKey)) !== null && _a !== void 0 ? _a : []),
            newKey,
        ];
        csInput.value =
            csInput.value
                .split(",")
                .filter((en, index) => en != newLabel && index < value.length - 1)
                .join(",") +
                "," +
                newLabel +
                ",";
        selectedOptionLabels = [...selectedOptionLabels, newLabel];
    }
    else {
        csInput.value =
            csInput.value
                .split(",")
                .filter((en, index) => en != newLabel && index < value.length)
                .join(",") + ",";
        value = value === null || value === void 0 ? void 0 : value.filter((en) => en != null && en != newKey);
        selectedOptionLabels = selectedOptionLabels.filter((en) => en != newLabel);
    }
    doFilter();
};
let seenOptions = {};
let options = [];
let setInitial;
let selectedOptionLabels = [];
const getSeenValue = (value) => seenOptions[value];
$: seenOptions = {
    ...seenOptions,
    ...Object.fromEntries(Object.values(options).map(({ value, label }) => [value, label])),
};
$: if (getSeenValue(value) && csInput != undefined) {
    csInput.value = getSeenValue(value);
}
$: if ((Array.isArray(value) ? !value.some((en) => en == null) : value != null) &&
    options.length > 0 &&
    setInitial == undefined &&
    csInput != undefined) {
    if (fieldData.optionsSearchQuery && fieldData.optionQuery) {
        queryClient({
            query: fieldData.optionQuery,
            variables: { id: value },
        }).then(({ data }) => {
            var _a;
            csInput.value = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.label;
            setInitial = csInput.value;
        });
    }
    else {
        if (isMulti) {
            const initialLabels = value.map((vEn) => { var _a; return (_a = options.find((en) => en["value"] == vEn)) === null || _a === void 0 ? void 0 : _a.label; });
            csInput.value = initialLabels + ",";
            selectedOptionLabels = [...initialLabels];
            setInitial = [...csInput.value];
        }
        else {
            csInput.value = (_b = options.find((en) => en["value"] == value)) === null || _b === void 0 ? void 0 : _b.label;
            setInitial = csInput.value;
        }
    }
}
setupOptions();
</script>

<style>.custom-select {
  position: relative;
  height: 100%;
}

.input-wrapper {
  display: grid;
  height: 100%;
}

input {
  grid-area: 1 / 1 / 2 / 2;
}

input::-ms-expand {
  display: none;
}

:global(.options) {
  border: 1px solid #aaa;
  border-radius: 0 0 0.25em 0.25em;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  margin-top: -0.5em;
  padding: 0;
  list-style-type: none;
  font-weight: normal;
  cursor: pointer;
  z-index: 22;
  position: absolute;
  width: calc(100% - 1px);
  background-color: #ffffff;
  max-height: 90px;
  overflow-y: scroll;
}

:global(.dark .options) {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity));
}

ul li {
  padding: 0.5rem;
}

ul li:hover {
  background-color: var(--primary-200);
}

.dark ul li:hover {
  background-color: var(--primary-800);
}

ul li:focus {
  border-color: var(--primary-200);
  border: 2px dashed;
}

button {
  width: 100%;
  text-decoration: underline;
  color: var(--secondary-500);
  text-align: left;
  padding: 0.5rem;
}

:global(.options button:hover) {
  background-color: var(--primary-200);
  border-color: var(--primary-200);
  border-width: 0 0 0 1px;
}

:global(.dark .options button:hover) {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
  border-width: 0 0 0 1px;
}

button:focus {
  border-color: var(--primary-200);
  border: 2px dashed;
}

.icon {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  fill: ButtonText;
  pointer-events: none;
  z-index: 2;
  grid-area: 1 / 1 / 2 / 2;
}

@media screen and (-ms-high-contrast: active) {
  .icon {
    fill: ButtonText;
  }
}

.hidden-visually {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

:global(.dark .options.customscrollbar) {
  --scrollbarBG: #252f3f;
}</style>

<div class="hidden-visually" aria-live="polite">
  {options.length}
  options available.
</div>
<div
  class="custom-select"
  on:keyup={doKeyAction}
  on:click={onClickSelect}
  use:clickOutside={{ callback: () => {
      showList = false;
      csState = SELECTSTATE.INITIAL;
    }, exclusions: [] }}
  role="combobox"
  aria-haspopup="listbox"
  aria-expanded={showList}
  aria-owns="custom-select-list">
  <div class="input-wrapper">
    <input
      autocomplete="off"
      type="text"
      {id}
      {placeholder}
      bind:this={csInput}
      {required}
      {disabled}
      class="form-input block w-full h-full {!fieldData.field || !!disabled ? 'bg-gray-200' : ''} {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''} sm:text-sm sm:leading-5 transition ease-in-out duration-150"
      aria-describedby="custom-select-info"
      aria-autocomplete="both"
      aria-controls="custom-select-list" />
    <span id="custom-select-info" class="hidden-visually">Arrow down for options
      or start typing to filter.</span>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      focusable="false"
      aria-hidden="true"
      class="icon dark:text-gray-200"
      role="img">
      {#if showList}
        <path
          fill="currentColor"
          d="M16 8c0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8 8-3.582 8-8zM1.5 8c0-3.59 2.91-6.5 6.5-6.5s6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5z" />
        <path
        fill="currentColor"
          d="M4.957 5.543l-1.414 1.414 4.457 4.457 4.457-4.457-1.414-1.414-3.043 3.043z" />
      {:else}
        <path
        fill="currentColor"
          d="M0 8c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8-8 3.582-8 8zM14.5 8c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5z" />
        <path
        fill="currentColor"
          d="M11.043 10.457l1.414-1.414-4.457-4.457-4.457 4.457 1.414 1.414 3.043-3.043z" />
      {/if}
    </svg>
  </div>

  <div class="options customscrollbar" class:hidden={!showList}>
    {#if fieldData.listAction}
      <button
        type="button"
        id="buttonaddnew">{fieldData.listAction.label}</button>
    {/if}
    <button type="button" id="buttonclear">Clear x</button>
    <ul bind:this={csList} role="listbox" id="custom-select-list">
      {#each options as option}
        <li role="option" tabindex="-1">{option?.label ?? option}</li>
      {/each}
    </ul>
  </div>
</div>
