<script lang="ts">var _a;
import Viewer from "./DataViz/Viewer.svelte";
import Parameters from "./DataViz/Parameter.svelte";
import { onMount } from "svelte";
import { queryClient, QueryStore } from "../GraphQL/query";
import FormField from "./Form/FormField.svelte";
import Table from "./DataViz/Table.svelte";
import Select from "./Form/Select.svelte";
import Loader from "../General/Loader.svelte";
import GeneralHeading from "../General/GeneralHeading.svelte";
import { clickOutside, setUpper } from "../random";
import { modalOpts, systemContext, systemExtendedContext } from "../stores";
import { getQueryVariableType, addQueryVariables, getQueryFirstSelectionName, getQueryFirstSelection } from "../GraphQL/helpers";
import { saveAs } from 'file-saver';
import { print } from "graphql";
import gql from "graphql-tag";
import { rx } from "../rxfsm";
import auth from "../auth";
const userRoles = auth.claims.pipe(rx.filter(val => val != null), rx.pluck("https://hasura.io/jwt/claims"), rx.pluck("x-hasura-allowed-roles"));
import { client } from "@src/GraphQL/client";
export let options;
let search;
let selectedVisualizationTag;
$: filterVisualization(search);
$: filterVisualization(selectedVisualizationTag);
let visualizationTags = {
    "type": "select",
    "field": "tags",
    "label": "Tags",
    "width": "full",
    "optionsQuery": options.tagsQuery
};
let orderByAscDesc = {
    "type": "select",
    "field": "order_by",
    "label": "Order By",
    "width": "full",
    "options": [{ "key": "Ascending" }, { "key": "Descending" }]
};
let visualizations;
const queryStore = new QueryStore({ paginate: true, query: options === null || options === void 0 ? void 0 : options.query, variables: { data_viz_name: search ? { _ilike: `%${search}%` } : { _ilike: `%%` }, tag_id: selectedVisualizationTag }, });
const queryDataStore = queryStore.getDataStore();
let queryVariablesStore = queryStore.variablesStore;
let didChangeLoad = false;
var delayTimer;
const filterVisualization = (filter) => {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(function () {
        didChangeLoad = true;
        $queryVariablesStore = { ...$queryVariablesStore, data_viz_name: search ? { _ilike: `%${search}%` } : { _ilike: `%%` }, tag_id: selectedVisualizationTag };
    }, 500);
};
$: if (!($queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.loading)) {
    didChangeLoad = false;
}
$: if ((_a = $queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.data) === null || _a === void 0 ? void 0 : _a.data)
    visualizations = $queryDataStore["data"]["data"];
let selectedVisualization;
let selectedVisualizationParamValues = {};
let selectedVisualizationParameters = [];
let selectedVisualizationViz = [];
let currentOffsetAmount = 0;
let currentOffsetPage = 0;
let offsetPageLength = 100;
let offsetPageCount;
let dataCount;
const changePage = (page) => {
    currentOffsetPage = page;
    currentOffsetAmount = currentOffsetPage * offsetPageLength;
    queryServer("HTML");
};
const nextPage = () => {
    if (currentOffsetPage == offsetPageCount - 1)
        return;
    this.changePage(currentOffsetPage + 1);
};
const lastPage = () => {
    if (currentOffsetPage == 0)
        return;
    changePage(currentOffsetPage - 1);
};
const findWhereValue = (query, k, v) => {
    const queryVarType = getQueryVariableType(query, `where_${k}`);
    if (queryVarType === null || queryVarType === void 0 ? void 0 : queryVarType.includes("_enum_comparison_exp")) {
        return { _eq: v };
    }
    switch (queryVarType) {
        case "String_comparison_exp":
            return { _ilike: `%${v}%` };
        case "uuid_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Int_comparison_exp":
            return {
                _gte: v[0],
                _lte: v[1],
            };
        case "timestamptz_comparison_exp":
            return {
                _gte: v[0],
                _lte: v[1],
            };
        case "numeric_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "bigint_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "date_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Boolean_comparison_exp":
            return { _eq: v };
        case "timestamp_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "jsonb_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
    }
};
let frameHtml;
const queryServer = async (type) => {
    if (!selectedVisualization.orderBy.direction || !selectedVisualization.orderBy.field) {
        //ALERT
    }
    //Need to get the first value if order by isnt defined
    const orderBy = selectedVisualization.orderByFields.length === 0 ? {} : {
        [selectedVisualization.orderBy.field ? selectedVisualization.orderBy.field : selectedVisualization.orderByFields[0].key]: selectedVisualization.orderBy.direction == "Ascending" ? "asc" : "desc"
    };
    //Add limit as well. If type is HTML then set to 100. If not select from the modal. Maybe only show modal if the wheres and limit is empty
    const variables = Object.fromEntries(Object.entries(selectedVisualizationParamValues).map(([key, value]) => {
        return [`where_${key}`, findWhereValue(selectedVisualization.data_viz.query, key, value !== null && value !== void 0 ? value : "")];
    }));
    const queryDocumentNode = gql(selectedVisualization.data_viz.query);
    const queryName = getQueryFirstSelectionName(selectedVisualization.data_viz.query);
    const sendingQueryDoc = (addQueryVariables(queryDocumentNode, { limit: "Int", offset: "Int", order_by: `[${queryName}_order_by!]` }));
    const sendingQuery = print(sendingQueryDoc);
    const aggregateQuery = gql `
        query AggregateQuery {
            aggregate: ${queryName}_aggregate { 
                aggregate { count } 
            } 
        }
    `;
    const queryVariables = JSON.parse(JSON.stringify(getQueryFirstSelection(selectedVisualization.data_viz.query)["arguments"]));
    aggregateQuery["definitions"][0]["selectionSet"]["selections"][0]["arguments"] = queryVariables;
    aggregateQuery["definitions"][0]["variableDefinitions"] = gql(sendingQuery)["definitions"][0]["variableDefinitions"].filter(({ variable: { name: { value } } }) => value.includes("where_"));
    client
        .query({ query: aggregateQuery, variables })
        .then(({ data }) => {
        var _a, _b;
        dataCount = (_b = (_a = data === null || data === void 0 ? void 0 : data.aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count;
        let newPageCount = dataCount / offsetPageLength;
        newPageCount = newPageCount < 1 ? 1 : Math.ceil(newPageCount);
        offsetPageCount = newPageCount;
    });
    let b64logo = "data:image/svg+xml;base64," + window.btoa($systemExtendedContext.logo);
    let data = {
        query: sendingQuery,
        query_variables: {
            ...variables,
            limit: offsetPageLength,
            offset: currentOffsetAmount,
            order_by: orderBy,
        },
        file: selectedVisualization.file,
        download_name: selectedVisualization.name,
        type: type,
        header: {
            name: selectedVisualization.name,
            organization: $systemContext.organization,
            logo: b64logo,
            date: new Date(),
            system: $systemContext.system,
            contexts: Object.entries($systemContext)
                .filter(([k, v]) => k != "system" && k != "organization")
                .map(([k, v]) => `${setUpper(k)}: ${v.label}`)
                .join(" — ")
        }
    };
    //add headers with bearer token
    await fetch($systemExtendedContext.server + '/dataVisualization/generateReport', {
        method: 'POST',
        body: JSON.stringify({
            "dataviz": data
        }),
        mode: 'cors',
        headers: {
            'content-type': 'application/json'
        }
    }).then(async (res) => {
        if (type == "HTML") {
            const html = await res.text();
            frameHtml = "data:text/html;charset=utf-8," + escape(html);
            completedVisualization = true;
        }
        else {
            let blob = await res.blob();
            let fileExtension = ".txt";
            switch (type) {
                case "Excel":
                    fileExtension = ".xlsx";
                    break;
                case "Word":
                    fileExtension = ".docx";
                    break;
                case "PDF":
                    fileExtension = ".pdf";
                    break;
                case "CSV":
                    fileExtension = ".csv";
                    break;
            }
            saveAs(blob, selectedVisualization.name + fileExtension);
        }
        //Download file here and show alert of download
    }).catch((error) => {
        console.error('Error:', error);
        throw error;
    });
    exportingVisualization = false;
    runningVisualization = false;
};
const getVariables = () => Object.fromEntries(Object.entries(selectedVisualizationParamValues)
    .filter(([k, v]) => v != undefined)
    .map(([k, v]) => [`param_${k}`, v]));
const selectVisualization = (visualization) => {
    runningVisualization = false;
    exportingVisualization = false;
    completedVisualization = false;
    let orderByFields = visualization.data_viz.params.map((en) => {
        return { key: en.field, label: en.label };
    });
    selectedVisualization = {
        ...visualization,
        orderBy: {},
        orderByFields: orderByFields
    };
    selectedVisualizationParameters = visualization.data_viz.params;
    selectedVisualizationViz = visualization.data_viz.data_viz;
    selectedVisualizationParamValues = {};
};
let runningVisualization = false;
let completedVisualization = false;
const runVisualization = async () => {
    if (!runningVisualization) {
        runningVisualization = true;
        exportingVisualization = false;
        queryServer("HTML");
    }
};
//On pagination change call runVisualization. How do get pages? Do aggregate from the main query name like the table
let exportingVisualization = false;
let otherExport = false;
let exportType = '';
const exportVisualization = async (type = 'PDF') => {
    console.log(selectedVisualizationParamValues);
    if (!exportingVisualization) {
        if (!Object.values(selectedVisualizationParamValues).every((el) => Array.isArray(el) ? el.every(el => !el) : !el)) {
            exportType = type;
            exportingVisualization = true;
            runningVisualization = false;
            queryServer(type);
        }
        else {
            modalOpts.set({
                title: `Large File`,
                description: "You didn't input any filers. This may take awhile. Are you sure you wish to continue?",
                color: "blue",
                icon: "info",
                exclusions: ["export-Btn-PDF", "export-Btn-Excel", "export-Btn-Word"],
                action: {
                    name: "I'm Sure",
                    callback: async () => {
                        exportType = type;
                        exportingVisualization = true;
                        runningVisualization = false;
                        queryServer(type);
                    },
                },
            });
        }
    }
};
</script>

<style>:global(.paginationButton) {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom-width: 1px;
  border-top-width: 1px;
  --border-opacity: 1;
  border-color: #d2d6dc;
  border-color: rgba(210, 214, 220, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
  line-height: 1rem;
  font-weight: 500;
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity));
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

:global(.dark .paginationButton) {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity));
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

:global(.paginationButton.active) {
  background-color: var(--secondary-200);
}

:global(.dark .paginationButton.active) {
  background-color: var(--secondary-700);
}

.paginationButton + .paginationButton {
  border-left-width: 1px;
}

.paginationButton:first-child {
  border-left-width: 1px;
  border-top-left-radius: 0.18rem;
  border-bottom-left-radius: 0.18rem;
}

.paginationButton:last-child {
  border-right-width: 1px;
  border-top-right-radius: 0.18rem;
  border-bottom-right-radius: 0.18rem;
}

.paginationButton:hover {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity));
}

.paginationButton:focus {
  z-index: 10;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity));
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
}

:global(.paginationButton:active) {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity));
}

:global(.dark .paginationButton:active) {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity));
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

a {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.18rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.selected {
  color: var(--secondary-900);
  background-color: var(--secondary-100);
}

.notselected {
  color: var(--secondary-600);
}</style>

<div class="mx-auto">
  <div class="pb-8">
    <div class="flex flex-col md:flex-row flex-wrap">
      <div class="w-full md:w-1/4">
        <div class="bg-white dark:bg-gray-800  shadow overflow-hidden sm:rounded-lg">
          <GeneralHeading title="Saved Visualizations" />
          <div class="px-3 pb-3">
            <div class="my-3">
              <div class="relative rounded-md shadow-sm">
                <input bind:value={search} id="search" class="form-input block w-full sm:text-sm sm:leading-5" placeholder="Search" />
              </div>
            </div>
            <div class="my-3">
              <div class="relative rounded-md shadow-sm">
                <Select
                bind:value={selectedVisualizationTag}
                id="visualization-tags"
                isUnsavedUpdates={true}
                roundRightonFocus={true}
                fieldData={visualizationTags}
                placeholder='Filter Tags'
                required />
              </div>
            </div>
            <ul>
              {#if (visualizations && visualizations.length > 0 && !didChangeLoad)}
                {#each visualizations as v, vi}
                  {#if v['roles'] ? auth.helper.checkRoles($userRoles, v['roles'].split(',')) : true}
                  <li class="w-full {vi === 0 ? 'border-t-2' : ''} border-b-2 dark:border-gray-600">
                    <button
                      on:click={() => selectVisualization(v)}
                      class="w-full block hover:bg-secondary-50 dark:hover:bg-secondary-700 focus:outline-none focus:bg-secondary-50 dark:focus:bg-secondary-700 transition duration-150 ease-in-out">
                      <div class="flex flex-row w-full items-center py-2 justify-between">
                        <div class="min-w-0 flex-1 flex items-center">
                          <div class="min-w-0 flex-1 px-2">
                            <div>
                              <div class="text-left text-sm leading-5 font-medium text-gray-800 dark:text-gray-200 truncate">{v.name}</div>
                              <div class="mt-2 flex items-center text-sm leading-5 text-secondary-500 dark:text-gray-300"><span class="truncate">{v.description}</span></div>
                            </div>
                            <div class="flex flex-row mt-2">
                              {#each v.saved_data_viz_tags as tag}
                                <span class="inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium bg-{tag.data_viz_tag.color}-100 text-{tag.data_viz_tag.color}-800">
                                  {tag.data_viz_tag.name}
                                </span>
                              {/each}
                            </div>
                          </div>
                        </div>
                        <div>
                          <svg class="h-5 w-5 text-secondary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                    </button>
                  </li>
                  {/if}
                {/each}
                <div class="flex mt-3">
                  <nav class="ml-auto self-end z-0 inline-flex shadow-sm rounded-md">
                    <button on:click={() => queryStore.lastPage()} class="paginationButton" aria-label="Previous">
                      <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0
                    010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>

                    {#each Array(queryStore.pageCount) as _, i}
                      <button on:click={() => queryStore.changePage(i)} class="paginationButton {i == queryStore.currentPage ? 'bg-secondary-200' : ''}">
                        {i + 1}
                      </button>
                    {/each}

                    <button on:click={() => queryStore.nextPage()} class="paginationButton" aria-label="Next">
                      <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010
                    1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </nav>
                </div>
              {:else if (visualizations && visualizations.length == 0) || didChangeLoad}
                <Loader/>
              {:else}
                <li>No Visualizations Found.</li>
              {/if}
            </ul>
          </div>
        </div>
      </div>
      <div class="w-full md:w-3/4 md:pr-8 flex flex-col rounded-md mt-4 md:mt-0">
        <div class="bg-white dark:bg-gray-800  px-4 py-5 sm:px-6 rounded-md md:ml-6">
          {#if selectedVisualization}
            <div class="-mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap border-b pb-4">
              <div class="mt-4">
                <h3 class="text-lg leading-6 font-medium text-secondary-900 dark:text-gray-200">{selectedVisualization.name}</h3>
                <p class="mt-1 text-sm leading-5 text-gray-700 dark:text-gray-300">{selectedVisualization.description}</p>
              </div>
              <div class="ml-4 mt-4 flex-shrink-0">
                <span class="inline-flex rounded-md shadow-sm">
                  <button
                    on:click={runVisualization}
                    type="button"
                    class="mr-3 relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:shadow-outline-primary focus:border-primary-700 active:bg-primary-700">
                    {runningVisualization ? 'Running...' : 'Run'}
                  </button>
                  <!-- This example requires Tailwind CSS v2.0+ -->
                  <span class="relative inline-flex shadow-sm rounded-md">
                    <button 
                    on:click={() => {exportVisualization('PDF')}}
                    id="export-Btn-PDF"
                    disabled={exportingVisualization}
                    type="button" class="relative inline-flex items-center px-4 py-2 border-r-2 border-white-100 text-sm leading-5 font-medium rounded-l-md text-white bg-primary-800 hover:bg-primary-600 focus:outline-none focus:shadow-outline-primary focus:border-primary-700 active:bg-primary-700">
                      {exportingVisualization ? 'Exporting ' + exportType + '...' : 'Export as PDF'}
                    </button>
                    <span class="-ml-px relative block" id="navDropItem-otherExport" >
                      <button id="navDropItem-otherExport-Btn" type="button" class="relative inline-flex items-center px-3 py-2 border border-transparent text-sm leading-5 font-medium rounded-r-md text-white bg-primary-800 hover:bg-primary-600 focus:outline-none focus:shadow-outline-primary focus:border-primary-700 active:bg-primary-700" on:click={() => {otherExport = !otherExport}}>
                        <span class="sr-only">Open options</span>
                        <!-- Heroicon name: chevron-down -->
                        <svg id='navDropItem-otherExport-Id' class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path on:click={(ev) => ev.stopPropagation()} fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                      </button>
                      {#if otherExport}
                        <div use:clickOutside={{ callback: () => {otherExport = !otherExport}, exclusions: ['navDropItem-otherExport', 'navDropItem-otherExport-Btn', 'navDropItem-otherExport-Thing', 'navDropItem-otherExport-Id'] }}
                        id='navDropItem-otherExport-Thing' class="z-50 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800  ring-1 ring-black ring-opacity-5">
                          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="option-menu">
                            <button id="export-Btn-PDF" disabled={exportingVisualization} on:click={() => {exportVisualization('CSV')}} class="block px-4 py-2 text-sm text-gray-700 hover:bg-white dark:bg-gray-800  hover:text-gray-900 w-full text-left" role="menuitem">
                              Export as CSV
                            </button>
                    
                            <button id="export-Btn-Excel" disabled={exportingVisualization} on:click={() => {exportVisualization('Excel')}} class="block px-4 py-2 text-sm text-gray-700 hover:bg-white dark:bg-gray-800  hover:text-gray-900 w-full text-left" role="menuitem">
                              Export as Excel
                            </button>
                          </div>
                        </div>
                      {/if}
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <h1 class="text-md my-3">Parameters:</h1>
            <div class="flex justify-between items-center flex-wrap sm:flex-no-wrap">
              {#key selectedVisualization}
                <ul class="w-full grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
                  {#if selectedVisualizationParameters}
                    {#each selectedVisualizationParameters as p}
                      <li class="col-span-1">
                        <FormField
                          bind:value={selectedVisualizationParamValues[p.field]}
                          fieldData={{ ...p, placeholder: p.label, width: 'full' }}
                          hideLabel />
                      </li>
                    {/each}
                  {/if}
                </ul>
              {/key}
            </div>
            <h1 class="text-md my-3 mt-5">Order By:</h1>
            <div class="flex justify-between items-center flex-wrap sm:flex-no-wrap">
              <ul class="w-full grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
                <Select
                bind:value={selectedVisualization.orderBy.field}
                id="visualization-tags"
                isUnsavedUpdates={true}
                roundRightonFocus={true}
                disabled={false}
                fieldData={{options: selectedVisualization.orderByFields, field: "Egg"}}
                placeholder='Order By'
                required />
              <Select
                bind:value={selectedVisualization.orderBy.direction}
                id="visualization-tags"
                isUnsavedUpdates={true}
                roundRightonFocus={true}
                fieldData={orderByAscDesc}
                placeholder='Asc or Desc'
                required />
              </ul>
            </div>
            {#if completedVisualization}
            <h1 class="text-md my-3 mt-5">Paginate:</h1>
            <div class="flex justify-between items-center flex-wrap sm:flex-no-wrap">
              <nav class="mr-auto self-start z-0 inline-flex shadow-sm rounded-md">
                <button on:click={() => lastPage()} class="paginationButton" aria-label="Previous">
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0
                010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </button>
  
                {#each Array(offsetPageCount) as _, i}
                  <button on:click={() => changePage(i)} class="paginationButton {i == currentOffsetPage ? 'bg-secondary-200' : ''}">
                    {i + 1}
                  </button>
                {/each}
  
                <button on:click={() => nextPage()} class="paginationButton" aria-label="Next">
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010
                1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
              Showing
              {currentOffsetAmount}
              to
              {currentOffsetAmount + offsetPageLength > dataCount ? dataCount : currentOffsetAmount + offsetPageLength}
              of
              {dataCount}.
            </div>
            {/if}
          {:else}
            <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
              <div class="ml-4 mt-4">
                <h3 class="text-lg leading-6 font-medium text-secondary-900 dark:text-gray-200">Select a Visualization</h3>
              </div>
              <div class="ml-4 mt-4 flex-shrink-0" />
            </div>
          {/if}
        </div>
        {#if completedVisualization}
          <div class="bg-white  px-4 py-5 border-b border-secondary-200 sm:px-6 rounded-md ml-6 overflow-scroll">
            <div class="flex justify-between items-center flex-wrap sm:flex-no-wrap shadow-sm">
              <iframe src="{frameHtml}" title="preview" class="w-full h-screen"/>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
