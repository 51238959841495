<script type="ts">var _a;
import { notificationOpts } from "../stores";
import { onMount } from "svelte";
import Yace from "yace";
import Prism from "prismjs";
import tab from "yace/dist/plugins/tab.js";
import history from "yace/dist/plugins/history.js";
import cutLine from "yace/dist/plugins/cutLine.js";
import preserveIndent from "yace/dist/plugins/preserveIndent.js";
import { QueryStore } from "../GraphQL/query";
import { mutateClient } from "../GraphQL/mutate";
export let options;
let editor;
const plugins = [
    history(),
    tab(),
    cutLine(),
    preserveIndent(),
];
const format = (useValue = editor === null || editor === void 0 ? void 0 : editor.value) => {
    let hadError = false;
    try {
        const parsedJson = JSON.parse(useValue);
        editor.update({ value: JSON.stringify(parsedJson, undefined, 2) });
    }
    catch (e) {
        hadError = true;
        if (e.name == "SyntaxError") {
            const errorSpot = parseInt(e.message.replace(/Unexpected (.*) in JSON at position /g, ""));
            editor.textarea.focus();
            editor.update({ selectionStart: errorSpot, selectionEnd: errorSpot + 1 });
            notificationOpts.set({
                title: "JSON Error Highlighted",
                color: "red",
                icon: "info",
                timeout: 5000,
            });
        }
        else {
            throw e;
        }
    }
    return { hadError };
};
const save = async () => {
    const { hadError } = format();
    if (!hadError) {
        await mutateClient({
            mutation: options.mutation,
            variables: {
                editor_value: JSON.parse(editor.value),
            },
        });
        notificationOpts.set({
            title: "Saved Value",
            color: "green",
            icon: "info",
            timeout: 5000,
        });
    }
};
console.log(options.query);
const queryStore = new QueryStore({ query: options === null || options === void 0 ? void 0 : options.query });
const queryDataStore = queryStore.getDataStore();
$: if (editor && ((_a = $queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.data) === null || _a === void 0 ? void 0 : _a.data))
    editor.update({ value: JSON.stringify($queryDataStore["data"]["data"]["data"], undefined, 2) });
function handleKeydown(event) {
    if (event.key === "s" && event.ctrlKey === true) {
        event.preventDefault();
        save();
    }
}
onMount(() => {
    editor = new Yace("#editor", {
        value: JSON.stringify($queryDataStore, undefined, 2),
        styles: {
            fontSize: "18px",
            "min-width": "100%",
        },
        highlighter: (v) => Prism.highlight(v, Prism.languages.javascript, "javascript"),
        plugins,
        lineNumbers: true,
    });
    editor.textarea.spellcheck = false;
});
</script>

<style global>:global(code[class*="language-"]),
  :global(pre[class*="language-"]) {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

:global(pre[class*="language-"]::-moz-selection),
  :global(pre[class*="language-"]) :global(::-moz-selection),
  :global(code[class*="language-"]::-moz-selection),
  :global(code[class*="language-"]) :global(::-moz-selection) {
  text-shadow: none;
  background: #b3d4fc;
}

:global(pre[class*="language-"]::selection),
  :global(pre[class*="language-"]) :global(::selection),
  :global(code[class*="language-"]::selection),
  :global(code[class*="language-"]) :global(::selection) {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  :global(code[class*="language-"]),
    :global(pre[class*="language-"]) {
    text-shadow: none;
  }
}

/* Code blocks */

:global(pre[class*="language-"]) {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:global(:not(pre)) > :global(code[class*="language-"]),
  :global(pre[class*="language-"]) {
  background: #f5f2f0;
}

/* Inline code */

:global(:not(pre)) > :global(code[class*="language-"]) {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

:global(.token.comment),
  :global(.token.prolog),
  :global(.token.doctype),
  :global(.token.cdata) {
  color: slatesecondary;
}

:global(.token.punctuation) {
  color: #999;
}

:global(.token.namespace) {
  opacity: 0.7;
}

:global(.token.property),
  :global(.token.tag),
  :global(.token.boolean),
  :global(.token.number),
  :global(.token.constant),
  :global(.token.symbol),
  :global(.token.deleted) {
  color: #905;
}

:global(.token.selector),
  :global(.token.attr-name),
  :global(.token.string),
  :global(.token.char),
  :global(.token.builtin),
  :global(.token.inserted) {
  color: #690;
}

:global(.token.operator),
  :global(.token.entity),
  :global(.token.url),
  :global(.language-css) :global(.token.string),
  :global(.style) :global(.token.string) {
  color: #9a6e3a;
  /* This background color was intended by the author of this theme. */
  background: hsla(0, 0%, 100%, 0.5);
}

:global(.token.atrule),
  :global(.token.attr-value),
  :global(.token.keyword) {
  color: #07a;
}

:global(.token.function),
  :global(.token.class-name) {
  color: #dd4a68;
}

:global(.token.regex),
  :global(.token.important),
  :global(.token.variable) {
  color: #e90;
}

:global(.token.important),
  :global(.token.bold) {
  font-weight: bold;
}

:global(.token.italic) {
  font-style: italic;
}

:global(.token.entity) {
  cursor: help;
}

:global(.jsoneditor) :global(button) {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-color: var(--secondary-300);
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--secondary-700);
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

:global(.dark :global(.jsoneditor) :global(button)) {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity));
}

:global(.jsoneditor) :global(button:hover) {
  color: var(--secondary-500);
}

:global(.jsoneditor) :global(button:focus) {
  z-index: 10;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity));
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
}

:global(.jsoneditor) :global(button:active) {
  background-color: var(--secondary-100);
  color: var(--secondary-700);
}

:global(.jsoneditor) :global(.editor-container) {
  width: 100%;
  height: 100%;
  padding-left: 1.5rem;
  position: relative;
  border: none;
  line-height: 1.5;
}</style>

<svelte:window on:keydown={handleKeydown} />

<div class="jsoneditor rounded-lg shadow px-5 py-6 sm:px-6 mb-5" style="background: #f7f7f7;">
  <div class="rounded-lg flex flex-row" style="min-height: 55vh">
    <div class="flex flex-col w-1/4">
      <span class="relative z-0 inline-flex shadow-sm rounded-md mr-auto">
        <button type="button" on:click={() => format()} class="rounded-l-md"> Format </button>

        <button type="button" on:click={save} class="-ml-px rounded-r-md"> Save </button>
      </span>
    </div>
    <div class="editor-container w-3/4">
      <div id="editor" />
    </div>
  </div>
</div>
