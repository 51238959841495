<script lang="ts">import { queryClient } from "../../GraphQL/query";
export let selectedRow;
export let expanded = false;
export let tableData;
export let leaficon;
export let nodeicon;
export let nodeQuery;
import Loader from "../../General/Loader.svelte";
let loading = false;
function selectOrToggle() {
    if (tableData.nodes) {
        if (!expanded && tableData.id && nodeQuery && !loading) {
            loading = true;
            //Query the thing with options.nodeQuery
            //Pass the variable
            queryClient({
                query: nodeQuery,
                variables: {
                    where_id: tableData.id,
                },
            }).then(({ data }) => {
                tableData.nodes = data.data[0].nodes;
                expanded = true;
                loading = false;
            });
        }
        else {
            expanded = !expanded;
        }
    }
    else if ((selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.id) == (tableData === null || tableData === void 0 ? void 0 : tableData.id)) {
        selectedRow = null;
    }
    else {
        selectedRow = tableData;
    }
}
</script>

<style>.icon-hover:hover {
  filter: brightness(0.5) invert(0);
}

.icon-hover-expanded {
  filter: brightness(1) invert(0);
}</style>

<div
  class="flex flex-row mt-1 px-3 py-2 hover:text-primary-600 cursor-pointer group {tableData.id && selectedRow?.id == tableData.id ? 'bg-gray-300 rounded-md' : 'bg-gray-200 rounded-md'}"
  on:click={selectOrToggle}>
  {#if !loading}
    <img class="h-5 w-5 my-auto {expanded ? 'icon-hover-expanded' : 'icon-hover'}" src="/icons/{tableData.nodes ? nodeicon : leaficon}.svg" alt="Icon" />
  {:else}
    <div class="my-auto">
      <Loader svgClasses="h-4 w-4 text-gray-600" classes="" />
    </div>
  {/if}
  <span
    class:expanded
    class="{expanded ? 'text-primary-800 group-hover:text-primary-700' : 'group-hover:text-primary-800 text-primary-700'} text-md font-bold ml-3 ">{tableData.name ?? "Nodes"}</span>
  {#if tableData.count && tableData.nodes}
    <span
      class="{expanded ? 'text-primary-800 group-hover:text-primary-700' : 'group-hover:text-primary-800 text-primary-700'} ml-auto">{tableData.count}</span>
  {:else if tableData.nodes && !tableData.count}
    <span
      class="{expanded ? 'text-primary-800 group-hover:text-primary-700' : 'group-hover:text-primary-800 text-primary-700'} ml-auto">{tableData.nodes.length}</span>
  {/if}
</div>

{#if expanded}
  <ul class="pl-1 border-l-2 ml-2 mt-1">
    {#if tableData.nodes}
      {#each tableData.nodes as node}
        <li class="ml-1">
          <svelte:self bind:selectedRow tableData={node} {leaficon} {nodeicon} {nodeQuery} />
        </li>
      {/each}
    {/if}
  </ul>
{/if}
