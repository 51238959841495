<script lang="ts">import Loader from "./Loader.svelte";
import ContextSetter from "../Components/ContextSetter.svelte";
import { stringSepToNorm } from "@src/random";
import { rx } from "../rxfsm";
import auth from "../auth";
const userRoles = auth.claims.pipe(rx.filter(val => val != null), rx.pluck("https://hasura.io/jwt/claims"), rx.pluck("x-hasura-allowed-roles"));
import { routeContext } from "../stores";
export let currentRoutePath;
export let config;
$: currentRoutePathSplit = currentRoutePath.split("/");
$: currentRoutePathEnd =
    currentRoutePathSplit[currentRoutePathSplit.length - 1];
const findRouteTabs = (routePath, source = config.routes) => {
    var _a;
    const splitRoutePath = routePath == "" || routePath == "/" ? ["index"] : routePath.split("/");
    const foundRoute = source.find((en) => {
        if (source.length == 1 && en.name.match(/\[(.*?)\]/g))
            return true;
        return en.name == splitRoutePath[0];
    });
    if (foundRoute === null || foundRoute === void 0 ? void 0 : foundRoute.subroutes)
        return findRouteTabs(splitRoutePath.slice(1).join("/"), foundRoute.subroutes);
    if (foundRoute === null || foundRoute === void 0 ? void 0 : foundRoute.tabroutes)
        return {
            contextsetters: (_a = foundRoute.tabcontextsetters) !== null && _a !== void 0 ? _a : [],
            tabs: foundRoute.tabroutes.map((tr) => ({
                name: tr.navName,
                route: tr.name,
                roles: tr.roles,
            })),
        };
    throw "No route found for " + routePath;
};
const { tabs, contextsetters, } = findRouteTabs(currentRoutePath);
let contextSettersDone = new Set();
</script>

<style>a {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.18rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

:global(a.selected) {
  color: var(--secondary-900);
  background-color: var(--secondary-100);
}

:global(.dark a.selected) {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
  background-color: var(--secondary-700);
}

:global(a.notselected) {
  color: var(--secondary-600);
}

:global(.dark a.notselected) {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

:global(a.notselected:hover) {
  color: var(--secondary-900);
  background-color: var(--secondary-50);
}

:global(.dark a.notselected:hover) {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--bg-opacity));
}</style>

<div class="mx-auto">
  <div class="pb-8 sm:px-0">
    <div class="flex md:flex-row-reverse flex-wrap">
      <div class="w-full md:w-1/4 md:pl-6 lg:pl-8 md:mb-0 mb-6">
        {#each contextsetters as contextsetter}
          <ContextSetter
            options={{ scale: 'sm', ...contextsetter }}
            useContext={'routeContext'}
            on:fieldhasvalue={({ detail }) => (contextSettersDone = new Set([
                ...contextSettersDone,
                detail,
              ]))} />
        {/each}

        <div class="bg-white dark:bg-gray-800  border border-gray-400 dark:border-gray-900 overflow-hidden sm:rounded-lg">
          <div class="p-3">
            <nav class="flex flex-col space-y-1">
              {#each tabs as { name, route, roles }}
                {#if roles ? auth.helper.checkRoles($userRoles, roles) : true}
                  <a
                    href="#{[...currentRoutePathSplit.slice(0, currentRoutePathSplit.length - 1), route].join('/')}"
                    class:selected={route == currentRoutePathEnd}
                    class:notselected={route != currentRoutePathEnd}
                    class="focus:outline-none group font-medium"
                    aria-current="page">
                    <span
                      class="truncate">{name ?? stringSepToNorm(route)}</span>
                  </a>
                {/if}
              {/each}
            </nav>
          </div>
        </div>
      </div>
      <div class="w-full md:w-3/4 md:pr-8 flex flex-col">
        {#if contextSettersDone.size == contextsetters.length}
          {#key $routeContext}
            <slot />
          {/key}
        {:else}
          <div
            class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-visible rounded-lg border border-gray-400 dark:border-gray-900">
            <div
              class="bg-white dark:bg-gray-800  px-4 py-3 rounded-b-lg sm:px-6">
              <div
                class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                <div class="ml-4 mt-2 w-full">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
