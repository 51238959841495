<script type="ts">import { notificationOpts } from "../stores";
let count = 0;
let toasts = [];
const animateOut = (node, { delay = 0, duration = 1000 }) => {
    function vhTOpx(value) {
        var w = window, d = document, e = d.documentElement, g = d.getElementsByTagName("body")[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
        return (y * value) / 100;
    }
    return {
        delay,
        duration,
        css: (t) => `opacity: ${(t - 0.7) * 1}; transform-origin: top right;`,
    };
};
const createToast = (toast) => {
    if (toast === null || toast === void 0 ? void 0 : toast.title) {
        toasts = [
            {
                timeout: 300,
                id: count,
                ...toast,
                width: "100%",
            },
            ...toasts,
        ];
        count = count + 1;
    }
};
const removeToast = (id) => {
    toasts = toasts.filter((t) => t.id != id);
};
$: {
    createToast($notificationOpts);
    notificationOpts.set(null);
}
</script>

<style>.toasts {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  margin-top: 4rem;
  padding: 0px 2rem;
  list-style: none;
  z-index: 900;
}

.toasts > .toast {
  position: relative;
  margin: 1.5vh 0;
  min-width: 30vw;
  animation: animate-in 600ms forwards;
  color: #fff;
}

.toasts > .toast > .content {
  padding: 1vw;
  display: block;
  font-weight: 500;
}

.toasts > .toast > .progress {
  position: absolute;
  bottom: 0;
  height: 6px;
  width: 100%;
  animation-name: shrink;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.toasts > .toast:before,
  .toasts > .toast:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  bottom: 0;
  left: 1vw;
  right: 1vw;
  border-radius: 100px / 10px;
}

.toasts > .toast:after {
  right: 1vw;
  left: auto;
  transform: skew(8deg) rotate(3deg);
}

@keyframes animate-in {
  0%,
    60%,
    75%,
    90%,
    to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes shrink {
  0% {
    width: 98vw;
  }

  100% {
    width: 0;
  }
}

@media (min-width: 480px) {
  @keyframes animate-in {
    0%,
      60%,
      75%,
      90%,
      to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: translate3d(3000px, 0, 0);
    }

    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0);
    }

    75% {
      transform: translate3d(10px, 0, 0);
    }

    90% {
      transform: translate3d(-5px, 0, 0);
    }

    to {
      transform: none;
    }
  }

  @keyframes shrink {
    0% {
      width: 30vw;
    }

    100% {
      width: 0;
    }
  }
}</style>

<ul class="toasts">
  {#each toasts as toast (toast.id)}
    <li class="toast" out:animateOut>
      <div class="rounded-md bg-{toast.color}-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Get more from https://tailwindui.com/components/application-ui/feedback/alerts -->
            <!-- Heroicon name: information-circle -->
            <svg class="h-5 w-5 text-{toast.color}-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm font-semibold leading-5 text-{toast.color}-800">{toast.title}</p>
          </div>
        </div>
      </div>
      <div class="progress rounded-b-md bg-{toast.color}-200" style="animation-duration: {toast.timeout}ms;" on:animationend={() => removeToast(toast.id)} />
    </li>
  {/each}
</ul>
