<script lang="ts">import { clickOutside, setUpper } from "@src/random";
import { fade } from "svelte/transition";
export let navigateCallback, route, currentRoutePath, extraClass, inline = false, icon = null;
const currentPageCls = "text-white bg-secondary-500";
const otherPageLinkCls = "text-secondary-300 hover:text-white hover:bg-secondary-600";
const anyPageLinkCls = "px-3 py-2 rounded-md font-medium focus:border-none focus:outline-none focus:text-white focus:bg-secondary-600 text-sm flex " + extraClass;
const isCurrentPage = (currentRoutePath && currentRoutePath === route.route) || (currentRoutePath === undefined && route.route === "");
let showDialog = false;
const toggle = () => {
    showDialog = !showDialog;
};
const isARouteCurrentPage = (route) => {
    return ((currentRoutePath && currentRoutePath === route) ||
        (currentRoutePath === "" && route === "index"));
};
</script>

<style>.currentPage {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
  background-color: var(--secondary-500);
}

.otherPageLink {
  color: var(--secondary-300);
}

.otherPageLink:hover {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
  background-color: var(--secondary-600);
}

.anySubPageLink {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.18rem;
  font-weight: 500;
}

.anySubPageLink:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}</style>

<div class="relative text-left font-medium">
  {#if icon}
    <button
      class="p-1 border-2 border-transparent text-secondary-400 rounded-full
      hover:text-white focus:outline-none focus:text-white focus:bg-secondary-600"
      aria-haspopup="true"
      id={'navDrop-' + route.name}
      on:click={toggle}
      aria-label={route.name ? route.name : route.route
            .split('-')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join('')}>
      {#if icon == 'user'}
        <svg
          id={'navDropIcon-' + route.name}
          class="mb-px mx-px"
          style="height:1.15em;font-size:1.05em;vertical-align:-.125em;overflow:visible;"
          viewBox="0 0 448 512"
          aria-hidden="true"
          role="img"
          xmlns="http://www.w3.org/2000/svg">
          <path
            id={'navDropIconP1-' + route.name}
            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96
            128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9
            16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5
            48 48 48h352c26.5 0 48-21.5
            48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
            fill="currentColor" />
        </svg>
      {:else if icon == 'notif'}
        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24" id={'navDropIcon-' + route.name}>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            id={'navDropIconP1-' + route.name}
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0
            00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0
            .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        </svg>
      {/if}
    </button>
  {:else}
    <button
      class="{isCurrentPage ? currentPageCls : otherPageLinkCls}
      {anyPageLinkCls}"
      aria-label="Button"
      aria-haspopup="true"
      id={'navDrop-' + route.name}
      on:click={toggle}>
      {route.name}
    </button>
  {/if}

  {#if showDialog}
    {#if inline}
      <div
        class="flex flex-col my-1 relative text-left font-medium">
        {#each route.subroutes as subroute}
          <a
            href="#{subroute.route}"
            id={'navDropItem-' + subroute.route}
            on:click={() => {
              if (navigateCallback) navigateCallback();
              toggle();
            }}
            class="anySubPageLink text-sm my-auto font-medium"
            class:currentPage={isARouteCurrentPage(subroute.route)}
            class:otherPageLink={!isARouteCurrentPage(subroute.route)}>
            {subroute.name ? subroute.name : subroute.route
              .split('/')
              .map((s) => setUpper(s))
              [subroute.route.split('/').length - 1].split('-')
              .map((s) => setUpper(s))
              .join(' ')}
          </a>
        {/each}
      </div>
    {:else}
      <div
        use:clickOutside={{ callback: toggle, exclusions: ['navDrop-' + route.name, 'navDropIcon-' + route.name, 'navDropIconP1-' + route.name, 'navDropIconP2-' + route.name, ...route.subroutes.map((sr) => 'navDropItem-' + sr.route)] }}
        class="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg"
        style="z-index: 1000;"
        transition:fade={{ duration: 150 }}>
        <div class="rounded-md bg-white dark:bg-gray-800  shadow-xs">
          {#each route.subroutes as subroute}
            <a
              href="#{subroute.route}"
              id={'navDropItem-' + subroute.route}
              on:click={() => {
                if (navigateCallback) navigateCallback();
                toggle();
              }}
              class="block px-4 py-2 text-sm leading-5 text-secondary-700
              hover:bg-secondary-100 hover:text-secondary-900 focus:outline-none dark:text-gray-200
              dark:hover:bg-secondary-700 dark:hover:text-gray-200"
              role="menuitem">
              {subroute.name ? subroute.name : subroute.route
                    .split('/')
                    .map((s) => setUpper(s))
                    [subroute.route.split('/').length - 1].split('-')
                    .map((s) => setUpper(s))
                    .join(' ')}
            </a>
          {/each}
        </div>
      </div>
    {/if}
  {/if}
</div>
